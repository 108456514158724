import { browser, createI18n, localeFrom } from '@nanostores/i18n';
import type { Locale } from 'date-fns';
import { atom, computed } from 'nanostores';

import { $settings } from './settings';

export const available = ['en', 'nl'] as const;
export const $locale = localeFrom(
  computed($settings, (settings) => settings.language !== 'auto' ? settings.language : undefined),
  browser({ available })
);

const locales: Record<typeof available[number], () => Promise<Locale>> = {
  en: () => import('date-fns/locale/en-US').then((module) => module.enUS),
  nl: () => import('date-fns/locale/nl').then((module) => module.nl)
};

$locale.subscribe((value) => {
  const module = locales[value];

  module().then((locale) => $dateLocale.set(locale));
});

export const $dateLocale = atom<Locale | undefined>();
export const i18n = createI18n($locale, {
  get: (code) => import(`../locales/${code}.ts`).then((module) => module.default)
});
