import { useStore } from '@nanostores/react';
import type { Store } from 'nanostores';

import { i18n } from '../stores/i18n';

const messages = i18n('common', {
  title: 'APK Signer Tool',
  settings: 'Settings',
  upload: 'Sign new APK',
  history: 'Signed APKs',
  keys: 'Keys',
  users: 'Users',
  integrations: 'Integrations',
  log: 'Audit Log',
  add: 'Add',
  remove: 'Remove',
  close: 'Close',
  empty: 'Empty',
  confirm: 'Confirm',
  cancel: 'Cancel',
  search: 'Search',
  type: 'Type',
  value: 'Value',
  id: 'ID',
  name: 'Name',
  email: 'Email',
  username: 'Username',
  created: 'Created',
  date: 'Date',
  version: 'Version',
  platform: 'Platform',
  packageName: 'Package Name',
  versionName: 'Version Name',
  versionCode: 'Version Code',
  label: 'Label',
  passphrase: 'Passphrase',
  information: 'Information',
  download: 'Download',
  never: 'Never',
  show: 'Show',
  hide: 'Hide'
});

export function useCommonMessages() {
  return useStore(messages);
}

export function useMessages<T extends Store>(store: T) {
  const common = useCommonMessages();
  const t = useStore(store);

  return { ...t, common };
}
